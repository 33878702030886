<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
    />
    <!-- <li
      class="nav-item has-sub redirect-to-projects"
    >
      <a
        href="#"
        target="_self"
        class="d-flex align-items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14px"
          height="14px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-package"
        ><line
          x1="16.5"
          y1="9.4"
          x2="7.5"
          y2="4.21"
        /><path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z" /><polyline points="3.27 6.96 12 12.01 20.73 6.96" /><line
          x1="12"
          y1="22.08"
          x2="12"
          y2="12"
        /></svg>
        <span class="menu-title text-truncate ">Quick Links</span>
      </a>
      <ul
        id="__BVID__147"
        class="menu-content collapse redirect-list"
      >
        <li
          v-for="(pl, i) in userData.project_list"
          :key="i"
          class="nav-item"
        >
          <a
            class="d-flex align-items-center"
            @click="redirectToProject(pl.porject_code)"
          >
            <span class="menu-title text-truncate">{{ pl.project_name }}</span>
          </a>
        </li>
      </ul>
    </li> -->
    <ul class="nav navbar-nav redirect-to-projects-vertical">
      <li
        v-for="(pl, i) in userData.project_list"
        :key="i"
        class="nav-item"
      >
        <b-link
          class="nav-link font-weight-bold text-primary"
          @click="redirectToProject(pl.porject_code)"
        >{{ pl.project_name }}
        </b-link>
      </li>
    </ul>
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import axios from 'axios'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import constants from '@/constants'
import { failedToast } from '@/utils/common'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
  methods: {
    redirectToProject(pcode) {
      axios.post(`${constants.COMPANY_API_PREFIX}/project-url`, { project_code: pcode, orange_hrms_code: this.userData.enc_orange_hrms_code }).then(response => {
        if (response.data.data) {
          window.open(response.data.data)
        } else {
          failedToast('Something went wrong!')
        }
      })
    },
  },
}
</script>
<style scoped>
.redirect-to-projects-vertical {
  margin-left: 15px;
}
</style>
