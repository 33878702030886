<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="20"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1" />

    <!-- <ul class="nav navbar-nav">
      <li
        v-for="(pl, i) in userData.project_list"
        :key="i"
        class="nav-item"
      >
        <b-link
          class="nav-link"
          @click="redirectToProject(pl.porject_code)"
        >{{ pl.project_name }}
        </b-link>
      </li>
    </ul> -->
    <locale />
    <search-bar class="d-block" />
    <dark-Toggler class="d-block" />
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <!-- <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.firstname }} {{ userData.lastname }}
            </p>
            <span
              v-if="userData.user_type === constants.USER_TYPE_ADMIN"
              class="user-status"
            >Admin</span>
            <span
              v-else-if="userData.user_type === constants.USER_TYPE_SUPER_ADMIN"
              class="user-status"
            >Super Admin</span>
            <span
              v-else-if="userData.user_type === constants.USER_TYPE_USER"
              class="user-status"
            >User</span>
          </div> -->
          <b-avatar
            size="sm"
            variant="light-primary"
            :src="profile_pic"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="redirectToProfile"
        >
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>{{ t('Profile') }}</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>{{ t('Logout') }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { useUtils } from '@core/libs/i18n'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import Locale from '@core/layouts/components/app-navbar/components/Locale.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SearchBar from '@core/layouts/components/app-navbar/components/SearchBar.vue'
// import useJwt from '@/auth/jwt/useJwt'
// import axios from 'axios'
import constants from '../../constants'
import store from '../../store'
// import { failedToast } from '@/utils/common'

export default {
  components: {
    BLink,
    Locale,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    SearchBar,
    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  data() {
    return {
      constants,
      profile_pic: JSON.parse(localStorage.getItem('userData')).profile_photo,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.$root.$on('refreshProfilePic', val => {
      this.profile_pic = val
    })
  },
  methods: {
    redirectToProfile() {
      this.$router.push({ name: 'profile' })
    },
    // redirectToProject(pcode) {
    //   axios.post(`${constants.COMPANY_API_PREFIX}/project-url`, { project_code: pcode, orange_hrms_code: this.userData.enc_orange_hrms_code }).then(response => {
    //     if (response.data.data) {
    //       window.open(response.data.data)
    //     } else {
    //       failedToast('Something went wrong!')
    //     }
    //   })
    // },
    logout() {
      if (this.userData.companyuserid) {
        store.dispatch('CompanyuserManage/logout').then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.message}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
        })
      } else {
        store.dispatch('companyStore/adminLogout').then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${response.data.message}`,
              icon: 'Success',
              variant: 'success',
              text: '',
            },
          })
        })
      }
      localStorage.clear()
      this.$router.push({ name: 'login' })
      // Redirect to login page
      // this.$router.push({ name: 'login' })
    },
  },
}
</script>
